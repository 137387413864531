import {
  STATUS_MESSAGE,
  ALERT_MESSAGE
} from '@/store/actions.type'
import {
  SET_STATUS_MESSAGE,
  SET_ALERT_MESSAGE
} from '@/store/mutations.type'

const state = {
  statusMessage: null,
  alertMessage: null,

  // Фильтры поверок
  verificationQuery: {
    region_id: null,
    district_id: null,
    verification_date_from: null,
    verification_date_to: null,
    meter_status_id: null,
    search: null,
    type_id: null,
    verifier_id: null,
    sortBy: ['verification_date'],
    sortDesc: [true],
    itemsPerPage: 50,
    page: 1
  },

  // Фильтры лидов
  leadQuery: {
    region_id: null,
    district_id: null,
    verification_date_from: null,
    verification_date_to: null,
    // lead_status_id: null,
    status: 'Новый',
    search: null,
    sortBy: ['verification_date'],
    sortDesc: [true],
    itemsPerPage: 50,
    page: 1
  }
}

const getters = {
  statusMessage (state: any) {
    return state.statusMessage
  },
  alertMessage (state: any) {
    return state.alertMessage
  }
}

const actions = {
  [STATUS_MESSAGE] (context: any, message: any) {
    context.commit(SET_STATUS_MESSAGE, message)

    setTimeout(() => {
      context.commit(SET_STATUS_MESSAGE, null)
    }, 3000)
  },

  [ALERT_MESSAGE] (context: any, message: any) {
    context.commit(SET_ALERT_MESSAGE, message)
  },

  hideAlertMessage (context: any) {
    context.commit(SET_ALERT_MESSAGE, null)
  }
}

const mutations = {
  [SET_STATUS_MESSAGE] (state: any, message: any) {
    state.statusMessage = message
  },

  [SET_ALERT_MESSAGE] (state: any, message: any) {
    state.alertMessage = message
  },

  SET_VERIFICATION_QUERY (state: any, query: any) {
    state.verificationQuery = query
  },

  SET_LEAD_QUERY (state: any, query: any) {
    state.leadQuery = query
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
