import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'

// Временно, менеджеры с ограниченными правами
export const TEMP_LIMITED_MANAGERS = [
  'disp1@gas-standard.ru',
  'disp2@gas-standard.ru'
]

export const TEMP_LIMITED_MANAGERS_MOG = [
  'mog_vost_manager@gas-standard.ru'
]

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_API_PATH
  },

  setHeader () {
    if (JwtService.getToken()) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`
    }
  },

  async query (resource: string, params: any) {
    this.setHeader()
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`)
    })
  },

  async get (resource: string, slug = '') {
    this.setHeader()
    return Vue.axios.get(`${resource}${slug ? '/' + slug : ''}`)
      .catch(error => {
        throw new Error(`ApiService ${error}`)
      })
      .then(data => data.data)
  },

  async post (resource: string, params: any = null) {
    this.setHeader()
    return Vue.axios.post(`${resource}`, params).then(data => data.data)
  },

  async update (resource: string, slug: any, params: any) {
    this.setHeader()
    return Vue.axios.put(`${resource}${slug ? '/' + slug : ''}`, params).then(data => data.data)
  },

  async put (resource: string, params: any = null) {
    this.setHeader()
    return Vue.axios.put(`${resource}`, params).then(data => data.data)
  },

  async delete (resource: string) {
    this.setHeader()
    return Vue.axios.delete(resource)
      .catch(error => {
        throw new Error(`ApiService ${error}`)
      })
      .then(data => data.data)
  }
}

export default ApiService

export const DevicesService = {
  get (region_id = false) {
    return ApiService.get('device' + (region_id ? ('?region_id=' + region_id) : ''))
  },
  add (device: any) {
    return ApiService.post('device', device)
  },
  delete (id: any) {
    return ApiService.delete(`device/${id}`)
  },
  update (id: any, device: any) {
    return ApiService.put(`device/${id}`, device)
  }
}

export const UsersService = {
  // Список пользователей
  index (query: any) {
    const queryParams = new URLSearchParams()

    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return ApiService.get(`users?${queryParams.toString()}`)
  },

  //
  workers (worker_type_id = false, region_id = false) {
    return ApiService.get('user?role=worker' + (worker_type_id ? ('&worker_type_id=' + worker_type_id) : '') + (region_id ? ('&region_id=' + region_id) : ''))
  },
  companies () {
    return ApiService.get('user?role=company')
  },
  managers () {
    return ApiService.get('user?role=manager')
  },
  filial_managers () {
    return ApiService.get('user?role=filial_manager')
  },
  coordinators () {
    return ApiService.get('user?role=coordinator')
  },
  add (user: any, role: any) {
    return ApiService.post(`user?role=${role}`, user)
  },
  delete (id: any) {
    return ApiService.delete(`user/${id}`)
  },
  update (id: any, user: any) {
    return ApiService.put(`user/${id}`, user)
  },
  // Отметить пользователя не-водителем
  not_a_driver () {
    return ApiService.post('not_a_driver')
  }
}

export const PricesService = {
  get () {
    return ApiService.get('prices')
  },
  add (price: any) {
    return ApiService.post('prices', price)
  },
  update (price: any) {
    return ApiService.put('prices', price)
  },

  // Удаление цены
  delete (priceId: any) {
    return ApiService.delete(`prices/${priceId}`)
  }
}

export const MetersService = {
  get () {
    return ApiService.get('meters')
  },
  add (meter: any) {
    return ApiService.post('meters', meter)
  },
  update (meterId: any, meter: any) {
    return ApiService.put(`meters/${meterId}`, meter)
  },
  delete (meterId: any) {
    return ApiService.delete(`meters/${meterId}`)
  }
}

export const MeterBrandsService = {
  get () {
    return ApiService.get('meter_brands')
  },
  add (meter: any) {
    return ApiService.post('meter_brands', meter)
  },
  update (meterId: any, meter: any) {
    return ApiService.put(`meter_brands/${meterId}`, meter)
  },
  delete (meterId: any) {
    return ApiService.delete(`meter_brands/${meterId}`)
  }
}

export const ListsService = {
  get (lists: Array<string> = []) {
    return ApiService.get(`lists?lists=${lists.join(',')}`)
  }
}

export const VerificationsService = {
  get (id = false, query: any = {}) {
    const queryParams = new URLSearchParams()
    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return !id ? ApiService.get(`verification?${queryParams.toString()}`) : ApiService.get(`verification/${id}`)
  },
  getByDates () {
    return ApiService.get('verification?by_date=1')
  },
  // Поверки с группировкой по дате для исполнителей
  getByDatesForWorker (page: any) {
    return ApiService.get(`verifications?page=${page}`)
  },
  add (verification: any) {
    return ApiService.post('verification', verification)
  },
  update (id: any, verification: any) {
    return ApiService.put(`verification/${id}`, verification)
  },
  updateImages (id: any, formData: any) {
    return ApiService.post(`verification/${id}/images`, formData)
  },
  // Поворот изображения
  rotateImage (id: any, rotate: any) {
    return ApiService.put(`verification_images/${id}/rotate?rotate=${rotate}`)
  },
  delete (id: any) {
    return ApiService.delete(`verification/${id}`)
  },
  // История изменения заявки
  getHistory (id: any) {
    return ApiService.get(`verifications/${id}/history`)
  },
  // Отчёт для директора
  getReport () {
    return ApiService.get('verification/report')
  },

  // Обновление координат
  updateCoordinates (id: any, verification: any) {
    return ApiService.put(`verification/${id}/coordinates`, verification)
  },

  // Обновление маршрута
  updateRoute (id: any, routeId: any) {
    return ApiService.put(`verification/${id}/route_put`, {
      route_id: routeId
    })
  },

  // Обновление статуса
  updateStatus (id: any, status: any) {
    return ApiService.put(`verification/${id}/status`, {
      status
    })
  },

  // Комментарий
  addCoordinatorComment (id: any, comment: any) {
    return ApiService.put(`verification/${id}/coordinator_comment`, {
      comment
    })
  }
}

// Работа с лидами
export const LeadsService = {
  async get (id = false, query: any = {}) {
    const queryParams = new URLSearchParams()
    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return !id ? ApiService.get(`leads?${queryParams.toString()}`) : ApiService.get(`leads/${id}`)
  },
  convert (id: any) {
    return ApiService.post(`leads/${id}/convert`)
  },
  processing (uploadedFileId: any) {
    return ApiService.post(`leads/processing/${uploadedFileId}`)
  },
  update (id: any, lead: any) {
    return ApiService.put(`leads/${id}`, lead)
  }
}

// Работа с файлами лидов
export const UploadedFilesService = {
  add (formData: FormData) {
    return ApiService.post('uploaded_files', formData)
  }
}

// Работа с маршрутными листами
export const RoutesService = {
  // Получение маршрутных листов
  get (id = false, query: any = {}) {
    const params = []

    if (query.verification_date) {
      params.push(`date=${query.verification_date}`)
    }
    if (query.type_id) {
      params.push(`type_id=${query.type_id}`)
    }

    return !id ? ApiService.get(`routes?${params.join('&')}`) : ApiService.get(`routes/${id}`)
  },

  //
  add (route: any) {
    return ApiService.post('routes', route)
  },

  //
  update (id: any, route: any) {
    return ApiService.put(`routes/${id}`, route)
  },

  //
  delete (id: any) {
    return ApiService.delete(`routes/${id}`)
  },

  //
  attachVerifications (id: any, verifications: any) {
    return ApiService.put(`routes/${id}/attach_verifications?verifications=${verifications}`)
  },

  // перестановка адресов поверок в маршрутном листе
  reorderVerifications (id: any, verificationOldPriority: any, verificationNewPriority: any) {
    return ApiService.put(`routes/${id}/reorder_verifications?verification_old_priority=${verificationOldPriority}&verification_new_priority=${verificationNewPriority}`)
  },

  // Построение маршрута
  sortVerifications (id: number) {
    return ApiService.put(`routes/${id}/sort_verifications`)
  }
}

// Работа с пробегом
export const MileageService = {
  // Получение пробегов
  get (id = false, user_id = false) {
    return !id ? ApiService.get('mileages' + (user_id ? '?user_id=' + user_id : '')) : ApiService.get(`mileages/${id}`)
  },

  // Сохранение пробега
  add (mileage: any) {
    return ApiService.post('mileages', mileage)
  }
}

// Работа с изображениями
export const ImageService = {
  // Сохранение изображения
  upload (image: any) {
    return ApiService.post('images', image)
  }
}

export const TypesService = {
  // Типы доходов и расходов
  getIncomeExpenses () {
    return ApiService.get('income_expenses_types')
  }
}

// Доходы и расходы
export const IncomeExpensesService = {
  getIncomeExpenses (params = {}) {
    return ApiService.query('income_expenses', { params })
  },

  amountIncomeExpenses (params = {}) {
    return ApiService.query('income_expenses/amount', { params })
  },

  store (incomeExpenses: any) {
    return ApiService.post('income_expenses', incomeExpenses)
  },

  update (incomeExpenses: any) {
    return ApiService.put(`income_expenses/${incomeExpenses.id}`, incomeExpenses)
  },

  // Инкассация
  moveToCheckbox (payments: any) {
    return ApiService.put('income_expenses/to_cashbox', { ids: payments })
  },

  summaryIncomeExpenses (params: any = {}) {
    return ApiService.query('income_expenses/summary', { params })
  },

  updateCashOnCard (cashOnCard: number) {
    return ApiService.put('income_expenses/cash_on_card', { cash_on_card: cashOnCard }).catch(e => {
      Vue.prototype.$alert('Произошла ошибка при сохранении: ' + e)
    })
  }
}

// Марки автомобилей
export const VehicleBrandsService = {
  // Список
  index (query: any) {
    const queryParams = new URLSearchParams()

    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return ApiService.get(`vehicle_brands?${queryParams.toString()}`)
  },

  // Модель для редактирования
  show (id: number) {
    return ApiService.get(`vehicle_brands/${id}`)
  },

  // Сохранение
  store (data: any) {
    return ApiService.post('vehicle_brands', data)
  },

  // Обновление
  update (id: number, data: any) {
    return ApiService.put(`vehicle_brands/${id}`, data)
  },

  // Удаление
  destroy (id: number) {
    return ApiService.delete(`vehicle_brands/${id}`)
  }
}

// Марки автомобилей
export const RouteService = {
  // Список
  index (query: any) {
    const queryParams = new URLSearchParams()

    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return ApiService.get(`admin_routes?${queryParams.toString()}`)
  },

  // Модель для редактирования
  show (id: number) {
    return ApiService.get(`admin_routes/${id}`)
  },

  // Сохранение
  store (data: any) {
    return ApiService.post('admin_routes', data)
  },

  // Обновление
  update (id: number, data: any) {
    return ApiService.put(`admin_routes/${id}`, data)
  },

  // Удаление
  destroy (id: number) {
    return ApiService.delete(`admin_routes/${id}`)
  }
}

// Офисы/склады
export const WarehousesService = {
  // Список
  index (query: any) {
    const queryParams = new URLSearchParams()

    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return ApiService.get(`warehouses?${queryParams.toString()}`)
  },

  // Модель для редактирования
  show (id: number) {
    return ApiService.get(`warehouses/${id}`)
  },

  // Сохранение
  store (data: any) {
    return ApiService.post('warehouses', data)
  },

  // Обновление
  update (id: number, data: any) {
    return ApiService.put(`warehouses/${id}`, data)
  },

  // Удаление
  destroy (id: number) {
    return ApiService.delete(`warehouses/${id}`)
  }
}

// Автомобили
export const VehiclesService = {
  // Список
  index (query: any) {
    const queryParams = new URLSearchParams()

    for (const [key] of Object.entries(query)) {
      if (query[key] !== null) {
        queryParams.append(key, query[key])
      }
    }

    return ApiService.get(`vehicles?${queryParams.toString()}`)
  },

  // Автомобиль для редактирования
  show (id: number) {
    return ApiService.get(`vehicles/${id}`)
  },

  // Сохранение
  store (data: any) {
    return ApiService.post('vehicles', data)
  },

  // Обновление
  update (id: number, data: any) {
    return ApiService.put(`vehicles/${id}`, data)
  },

  // Удаление
  destroy (id: number) {
    return ApiService.delete(`vehicles/${id}`)
  }
}

// Логистика
export const LogisticService = {
  // Получить токен доступа
  getToken () {
    return ApiService.get('logistic/token')
  }
}
